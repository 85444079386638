import React, { FormEvent, FunctionComponent } from 'react';
import API from '@/Services/API';
import Button from '@/Components/Button';
import useForm from '@/Hooks/useForm';
import Field from '@/Components/Inputs/Field';
import route from 'ziggy-js';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '@/Contexts/AuthContext';
import { User } from '@/Types/Models/Auth/user';
import { Token } from '@/Types/Models/Auth/token';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment/moment';
import BackupLocalData from '@/Components/BackupLocalData';

const Login: FunctionComponent = () => {
	const navigate = useNavigate();
	const { setToken, setUser, device, setDevice, mobileMode } = useAuth();

	const { data, setData, errors, loadErrors } = useForm({
		email: '',
		password: ''
	});

	const submit = (e: FormEvent) => {
		e.preventDefault();

		API.get('sanctum/csrf-cookie').then(() => {
			API.post(route('api.login'), data).then((response) => {
				setUser && setUser(response.data.user as User);
				setToken && setToken(response.data.token as Token);

				if (device === undefined) {
					setDevice && setDevice({
						uuid: uuidv4(),
						created_at: moment().format('YYYY-MM-DD HH:mm:ss')
					});
				}

				navigate('/');
			}).catch(({ response }) => {
				if (response?.data?.message) {
					localStorage.setItem('flash-error', response?.data?.message);
				}

				loadErrors(response?.data?.errors);
			});
		});
	};

	return (
		<div className="LoginPage">
			<h1 className="float-left w-full mb-6">Login</h1>
			<small className="text-sm">({process.env.REACT_APP_VERSION})</small>
			<form className="w-full block grid grid-col-1 gap-6" onSubmit={submit}>
				<div className="col-span-1">
					<Field label="Email *"
						   name="email"
						   onChange={setData}
						   error={errors.email}
						   value={data.email}
					/>
				</div>

				<div className="col-span-1">
					<Field label="Password *"
						   name="password"
						   type="password"
						   onChange={setData}
						   error={errors.password}
						   value={data.password}
					/>
				</div>

				<div className="col-span-1">
					<Button>
						Log in
					</Button>
				</div>

				<div className="col-span-1">
					<Link
						to="/forgot-password"
						className="btn-link"
					>
						Forgot your password?
					</Link>
				</div>

				<div className="col-span-1">
					<BackupLocalData/>
				</div>
			</form>
		</div>
	);
};

export default Login;