import React, { ChangeEvent, FunctionComponent, useState } from 'react';
import Button from '@/Components/Button';
import { Reports } from '@/Types/Models/Projects/report';
import Modal from '@/Components/Modals/Modal';
import Checkbox from '@/Components/Inputs/Checkbox';
import API from '@/Services/API';
import route from 'ziggy-js';
import { Project } from '@/Types/Models/Projects/project';
import useForm from '@/Hooks/useForm';
import { useAuth } from '@/Contexts/AuthContext';

interface IProps {
    project: Project;
    reports: Reports;
}

interface IData {
	contacts: string[];
	template: string;
	reports: number[];
	format?: string;
}

const DownloadReport: FunctionComponent<IProps> = ({ project, reports }) => {
	const [openModal, setOpenModal] = useState<boolean>(false);
	const { data, setData } = useForm<IData>({
		contacts: project?.contacts?.map((contact) => contact.value) ?? [],
		template: '',
		reports: [],
		format: undefined,
	});
	const { user } = useAuth();

	const handleDownload = async () => {
		const selectedReports = data.reports;
		const selectedFormat = data.format;

		if (selectedReports.length === 0 || !selectedFormat) {
			// Handle error or display a message
			return;
		}

		const downloadPromises = selectedReports.map(reportId => {
			return API.get(route('api.projects.reports.download', {
				project: project.id,
				report: reportId,
				format: selectedFormat,
				url: true,
			})).then(response => {
				if (response.status === 200) {
					return response.data.url;
				} else {
					// Handle error for a specific report
					throw new Error(`Failed to download report ${reportId}`);
				}
			});
		});

		try {
			const downloadUrls = await Promise.all(downloadPromises);

			// Open each download URL in a new tab
			downloadUrls.forEach(url => {
				window.open(url, '_blank');
			});
		} catch (error) {
			// Handle overall error for all reports
			console.error('Error downloading reports');
		}
	};

	const handleUpdateReports = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked) {
			setData('reports', [
				...(data.reports ?? []),
				parseInt(e.target.value)
			]);
		} else {
			setData('reports', (data.reports ?? []).filter((report) => report !== parseInt(e.target.value)));
		}
	};

	return (
		<>
			<Button type="button" onClick={() => setOpenModal(!openModal)}>Download Reports</Button>

			{openModal && (
				<Modal openModal={openModal} onClose={() => setOpenModal(!openModal)}>
					<div>
						<h3 className="mb-3">Week {reports[0].week} Reports</h3>

						<div className="grid grid-cols-2 gap-3 mb-6">
							{
								reports.filter(report => report.weekly_report_sections?.length || report.observations?.length || report.snags?.length).map((report, index) => (
									<div key={index} className="grid-span-1 my-auto">
										<Checkbox
											checked={report.id ? data.reports.includes(report.id) : false}
											onChange={handleUpdateReports}
											label={report.type}
											value={report.id}
										/>
									</div>
								))
							}
						</div>

						<h3 className="mb-3">Format *</h3>
						<div className="grid grid-cols-2 gap-3" key={data.format}>
							{
								['pdf', user?.role?.name === 'staff' ? '' : 'docx'].filter(v => v.length > 0).map((format, index) => (
									<div key={index} className="grid-span-1 my-auto">
										<Checkbox
											checked={data.format === format}
											onChange={e => setData('format', e.target.value)}
											label={`${format.toUpperCase()} Document`}
											value={format}
										/>
									</div>
								))
							}
						</div>

						<div className="w-full flex justify-end mt-6">
							<Button type="button" onClick={handleDownload} disabled={!(data.reports !== undefined && data.reports.length > 0 && data.format !== undefined)}>Download</Button>
						</div>
					</div>
				</Modal>
			)}
		</>
	);
};

export default DownloadReport;