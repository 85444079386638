import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Section } from '@/Components/Sections';
import Button from '@/Components/Button';
import WeeklyReportForm from './WeeklyReportForm';
import useForm from '@/Hooks/useForm';
import { useLayout } from '@/Contexts/LayoutContext';
import { useLocation, useNavigate, unstable_usePrompt, useParams } from 'react-router-dom';
import { Report } from '@/Types/Models/Projects/report';
import { db } from '@/Services/IndexedDB';
import reactRoute from '@/Utilities/routes';
import API from '@/Services/API';
import route from 'ziggy-js';
import { useAuth } from '@/Contexts/AuthContext';
import { Project } from '@/Types/Models/Projects/project';
import ObservationForm from '@/Pages/Projects/Reports/ObservationForm';
import SnagForm from '@/Pages/Projects/Reports/SnagForm';
import Loader from '@/Components/Loader';
import { clearAllTinyMceData } from '@/Utilities/helpers';

const Create: FunctionComponent = () => {
	const { setHeader, setAction } = useLayout();
	const navigate = useNavigate();
	const { state } = useLocation();
	const { id } = useParams();
	const { offline, mobileMode, calculatePhotoCount } = useAuth();

	const [project, setProject] = useState<Project | null>(null);
	const [report, setReport] = useState<Report | null>(null);
	const [isLocalCopy, setIsLocalCopy] = useState<boolean>(false);

	const { data, setData, errors, loadErrors } = useForm<Report>({
		id: undefined,
		project_id: parseInt(id as string),
		type: state?.type,
		week: state?.week,
	});

	const getData = useCallback((projectId: string | number, offline: boolean | undefined) => {
		const notFound = () => {
			if (!offline) {
				console.log('fetching from server');
				API.get(route('api.projects.show', [projectId])).then(({ data }) => {
					setProject(data.data);
				});
			} else {
				console.log('offline');
				navigate(reactRoute('not-found'));
			}
		};

		db.projects.get(typeof projectId === 'string' ? parseInt(projectId) : projectId).then(project => {
			if (project) {
				setProject(project as Project);
				setIsLocalCopy(true);
			} else {
				notFound();
			}
		}).catch((e) => {
			alert(e);
			notFound();
		});
	}, []);

	useEffect(() => {
		if (id) {
			getData(id, offline);
		}
	}, [id]);

	const submit = () => {
		if (offline || mobileMode) {
			if (project?.id) {
				db.projects.update(project?.id, {
					...project,
					reports: [
						...(project?.reports ?? []),
						data
					]
				}).then(() => {
					clearAllTinyMceData();

					localStorage.setItem('flash-success', `${report?.type} has been created in the local database. When you are back online, please sync to the server.`);
					window.onbeforeunload = null;
					mobileMode && calculatePhotoCount && calculatePhotoCount();
					navigate(reactRoute('projects.reports.show', [project?.id, report?.id ?? 'undefined']), {
						state: {
							week: data?.week,
							type: data?.type,
							canEdit: true,
							report: data,
						}
					});
				});
			}
		} else {
			if (project?.id) {
				API.post(route('api.projects.reports.store', [project?.id]), data).then(({ data: response }) => {
					clearAllTinyMceData();
					if (response?.data?.id) {
						window.onbeforeunload = null;
						navigate(reactRoute('projects.reports.show', [project?.id, response.data.id]), {
							state: {
								week: response.data.week,
								type: response.data.type,
								canEdit: true,
								report: response.data,
							}
						});
					}
				}).catch(({ response }) => loadErrors(response?.data?.errors));
			}
		}
	};

	const handleBack = () => {
		navigate(reactRoute('projects.show', [project?.id], {
			tab: 'inspection'
		}));
	};

	useEffect(() => {
		if (state?.type) {
			setHeader && setHeader(<div className="flex w-100 gap-5">
				<Button type="button" className="back-link" onClick={handleBack}>
					<svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512">
						<path
							d="M18.2 273l-17-17 17-17L171.8 85.4l17-17 33.9 33.9-17 17L93.1 232 424 232l24 0 0 48-24 0L93.1 280 205.8 392.6l17 17-33.9 33.9-17-17L18.2 273z"/>
					</svg>
				</Button>
				<h2 className="leading-tight my-auto">Create {state?.type}</h2>
			</div>);
		}

		const onUnload = (e: BeforeUnloadEvent|PageTransitionEvent) => {
			e.preventDefault();
			e.returnValue = 'Are you sure you like to go back. Any unsaved changes will be lost.';
		};

		window.addEventListener('beforeunload', onUnload);
		// window.addEventListener('pagehide', onUnload);

		return () => {
			window.removeEventListener('beforeunload', onUnload);
			// window.removeEventListener('pagehide', onUnload);
		};
	}, []);

	useEffect(() => {
		setAction && setAction(
			<Button type="button" onClick={submit}>Create Report</Button>
		);
	}, [data]);


	if (project === null) {
		return <Loader/>;
	}

	return (
		<div className="ReportCreatePage">
			<Section>
				<div>
					{
						state?.type === 'Weekly Report' && (
							<WeeklyReportForm data={data} setData={setData} errors={errors} canEdit/>)
					}
					{
						state?.type === 'Defect and Observation Report' && (
							<ObservationForm data={data} setData={setData} errors={errors} canEdit/>)
					}
					{
						state?.type === 'Snag Report' && (
							<SnagForm data={data} setData={setData} errors={errors} canEdit/>)
					}
					<div className="flex clear-both  w-full float-leftjustify-end mt-6">
						<Button className="ml-auto" type="button" onClick={submit}>Create Report</Button>
					</div>
				</div>
			</Section>
		</div>
	);
};

export default Create;
